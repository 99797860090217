@import "../../global.scss";


.contact{
    background-color: white;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
       
    }
    

    .left{
       display: flex;
       flex: 8;
       overflow: hidden;
       
        
        img{
            height: 100%;
    
            @include mobile{
                height: 70%;
                
               
            } 
        }

    }

    .right{
        display: flex;
        flex:4 ;
        flex-direction: column;
        align-items:center;
        justify-content: center;

        @include mobile {
            align-content: space-between;
        }

        h2{
            font-size: 30px;
            
        }

        form{
            
            height:70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            @include mobile{
                height: 70%;
                align-content:space-between;
                justify-content: space-evenly;
               
            } 
            

            input{
                width: 300px;
                height: 30px;
                font-size: 14px;
            }

            textarea{
                width: 300px;
                height: 300px;
                font-size: 14px;

            }

            button{
                width: 150px;
                height:30px;
                background-color: $secondaryColor;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor:pointer;
                
                &:focus{
                    outline: none;
                }


            }

        }
    }


}