@import "../../global.scss";

.portfolio{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size: 50px;
        margin: 10px;
        
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;
        
    
    

    }
    .container{
        display: flex;
        height: 600px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .card {
           border:none;
           border-radius: 20px;
           width: 300px;
           height:500px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            margin: 10px 20px;
            
        
        

        .item{
           width: 300px;
           height: 375px;
           border-radius: 20px;
           border: 1px solid rgb(240,239,239);
           
           display: flex;
           align-items: center;
           justify-content: center;
           color: white;
           position: relative ;
           transition: all .5s ease;
           cursor:pointer;

            h3{
                position: absolute;
                font-size: 20px;
            }

    

            img{ 
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index:1;

                
            }

            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }

        }

        } 
        
        h4{
            margin: 10px;
        }

        p{
            font-size: 12px;
            margin:15px;
        }
    }
}