@import "../../global.scss";

.bio{
    background-color: white;
    display: flex;
    flex-direction: column;
   justify-content: center;
    align-items: center;
    
   
    
    h1{
        font-size: 50px;
        margin:10px;
    }

    .container{
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;
        margin:10px;

        

            .item{
                display: flex;
                font-size: 20px;
                
               
            }

        
    }
    .profiles{
        display: flex;
        
        .iconItem{
            width: 100px;
            height: 100px;
            border-radius: 10px;
            cursor:pointer;
            position: relative;
            color:white;
            transition: all .5s ease;
            display: flex;
            align-items: center;
            justify-content: center;
        

            h5{
                
                font-size: 10px;

            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            &:hover{
                background-color: $mainColor;
                img{
                    opacity: 0.2;
                    z-index: 0; 
                }
            }
        }    


    }

    
}